export const bookConstants = {
  GET_BOOK_SUCCESS: 'GET_BOOK_SUCCESS',
  GET_BOOK_FAILURE: 'GET_BOOK_FAILURE',
  GET_BOOKS_SUCCESS: 'GET_BOOKS_SUCCESS',
  GET_BOOKS_FAILURE: 'GET_BOOKS_FAILURE',
  SEARCH_BOOKS_SUCCESS: 'SEARCH_BOOKS_SUCCESS',
  SEARCH_BOOKS_FAILURE: 'SEARCH_BOOKS_FAILURE',
  GET_BOOK_COUNT_SUCCESS: 'GET_BOOK_COUNT_SUCCESS',
  GET_BOOK_COUNT_FAILURE: 'GET_BOOK_COUNT_FAILURE',
  GET_BOOKS_BY_CATEGORY_SUCCESS: 'GET_BOOKS_BY_CATEGORY_SUCCESS',
  GET_BOOKS_BY_CATEGORY_FAILURE: 'GET_BOOKS_BY_CATEGORY_FAILURE',
};

export const categoryConstants = {
  GET_CATEGORIES_SUCCESS: 'GET_CATEGORIES_SUCCESS',
  GET_CATEGORIES_FAILURE: 'GET_CATEGORIES_FAILURE',
};

export const seriesConstants = {
  GET_SERIES_SUCCESS: 'GET_SERIES_SUCCESS',
  GET_SERIES_FAILURE: 'GET_SERIES_FAILURE',
  GET_ALL_SERIES_SUCCESS: 'GET_ALL_SERIES_SUCCESS',
  GET_ALL_SERIES_FAILURE: 'GET_AlL_SERIES_FAILURE',
  CHANGE_ACTIVE_SERIES: 'CHANGE_ACTIVE_SERIES',
};

export const userConstants = {
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_FAILURE: 'LOGIN_FAILURE',
  LOGIN_FAILURE_USERNAME: 'LOGIN_FAILURE_USERNAME',
  SIGNUP_SUCCESS: 'SIGNUP_SUCCESS',
  SIGNUP_FAILURE: 'SIGNUP_FAILURE',
  SIGNUP_FAILURE_USERNAME: 'SIGNUP_FAILURE_USERNAME',
  LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
  LOGOUT_FAILURE: 'LOGOUT_FAILURE',
  GET_ME_SUCCESS: 'GET_ME_SUCCESS',
  GET_ME_FAILURE: 'GET_ME_FAILURE',
};

export const basketActions = {
  POST_BOOK_SUCCESS: 'POST_BOOK_SUCCESS',
  POST_BOOK_FAILURE: 'POST_BOOK_FAILURE',
  POST_BOOK_FAILURE_MAX_BOOK: 'POST_BOOK_FAILURE_MAX_BOOK',
  PUT_BOOK_SUCCESS: 'PUT_BOOK_SUCCESS',
  PUT_BOOK_FAILURE: 'PUT_BOOK_FAILURE',
  PUT_BOOK_FAILURE_MAX_BOOK: 'PUT_BOOK_FAILURE_MAX_BOOK',
  DELETE_BOOK_SUCCESS: 'DELETE_BOOK_SUCCESS',
  DELETE_BOOK_FAILURE: 'DELETE_BOOK_FAILURE',
  GET_BASKET_SUCCESS: 'GET_BASKET_SUCCESS',
  GET_BASKET_FAILURE: 'GET_BASKET_FAILURE',
  PUT_ORDER_SUCCESS: 'PUT_ORDER_SUCCESS',
  PUT_ORDER_FAILURE: 'PUT_ORDER_FAILURE',
  GET_ORDERS_SUCCESS: 'GET_ORDERS_SUCCESS',
  GET_ORDERS_FAILURE: 'GET_ORDERS_FAILURE',
};

export const stateActions = {
  OPEN_LOGIN_MODAL: 'OPEN_LOGIN_MODAL',
  CLOSE_LOGIN_MODAL: 'CLOSE_LOGIN_MODAL',
  OPEN_FULL_BASKET_MODAL: 'OPEN_FULL_BASKET_MODAL',
  CLOSE_FULL_BASKET_MODAL: 'CLOSE_FULL_BASKET_MODAL',
  OPEN_BASKET_INFO_MODAL: 'OPEN_BASKET_INFO_MODAL',
  CLOSE_BASKET_INFO_MODAL: 'CLOSE_BASKET_INFO_MODAL',
  DONT_SUGGEST_LOGIN: 'DONT_SUGGEST_LOGIN',
  OPEN_EVENT_MODAL: 'OPEN_EVENT_MODAL',
  CLOSE_EVENT_MODAL: 'CLOSE_EVENT_MODAL',
};
